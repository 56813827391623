/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. preloader
03. global styles
04. buttons
05. header
06. welcome area
07. home
08. blog
09. our work
10. aside
11. contact
12. footer
--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&amp;subset=latin-ext");

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 20px !important;
  color: #000;
}

h1 {
  font-weight:500;
  font-size: 36px;
}

h2 {
  font-weight:500;
  font-size: 32px;
}

h3 {
  font-weight:500;
  font-size: 28px;
}

h4 {
  font-weight:500;
  font-size: 24px;
}

h5 {
  font-weight:500;
  font-size: 20px;
}

h6 {
  font-weight:500;
  font-size: 16px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, div pre,
a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
figure, header, nav, section, article, aside, footer, figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header, nav, section, article, aside, footer, hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}
img{
  width: 100%;
}

html, body {
  font-family: "Quicksand", sans-serif;
  font-weight:500;
  background-color: #fff;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #032644;
}

ul {
  margin-bottom: 0px;
}

/*Extra Pages*/
.lockscreen-box-body {
  -webkit-box-shadow: 0 2px 2px 0 rgba(128, 128, 128, 0.14), 0 3px 1px -2px rgba(128, 128, 128, 0.2), 0 1px 5px 0 rgba(128, 128, 128, 0.12);
  box-shadow: 0 2px 2px 0 rgba(128, 128, 128, 0.14), 0 3px 1px -2px rgba(128, 128, 128, 0.2), 0 1px 5px 0 rgba(128, 128, 128, 0.12);
}

.has-feedback {
  position: relative;
}

.main_color{
  color:#dc3545;
}
.lg-pop{
  border-radius: 20px;
  background-color: #ffff;
}

.has-feedback .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.register-page .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}
.theme-primary{
  height: 100vh;
  background-image:url(../assets/login-signup/loginBg.gif);
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginIcon{
  /* background: red; */
  font-size: 30px;
  color: #fff;
}

.loginIcon :hover{
color:#dc3545;
}

.login-page .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.lockscreen .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.content-top-agile {
  text-align: center;
  position: relative;
}

.auth-2 {
  width: 500px;
  margin: 0;
  padding: 7% 30px;
  float: right;
  height: 100%;
  overflow-y: scroll;
}

.auth-logo {
  text-align: center;
  font-weight: 300;
}

.auth-body {
  padding: 20px 20px 10px;
}

.auth-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px;
}

.auth-2-outer {
  display: block;
}

@media (max-width: 767px) {
  .content-top-agile {
    border-radius: 25px 25px 0 0;
  }

  .content-bottom {
    padding: 3em;
    background: #ffffff;
    border-radius: 0 0 25px 25px;
  }

  .auth-2 {
    width: 300px;
    margin: 0px auto;
    float: none;
    border-radius: 5px;
    height: auto;
  }

  .auth-2-outer {
    display: -ms-flexbox;
    display: flex;
  }
}

.error-page-title {
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}

.error-page>.error-content, .login-box .fog-pwd, .mailbox-attachment-icon, .mailbox-attachment-info, .mailbox-attachment-size {
  display: block;
}

/*lable floting*/
.has-warning .bar:before, .has-warning .bar:after {
  background: #F6B749;
}

.has-warning .form-control:focus~label, .has-warning .form-control:valid~label {
  color: #F6B749;
}

.has-success .bar:before, .has-success .bar:after {
  background: #04a08b;
}

.has-success .form-control:focus~label, .has-success .form-control:valid~label {
  color: #04a08b;
}

.has-error .bar:before, .has-error .bar:after {
  background: #FF6C6C;
}

.has-error .form-control:focus~label, .has-error .form-control:valid~label {
  color: #FF6C6C;
}

.has-feedback label~.t-0 {
  top: 0;
}

.form-group.error input, .form-group.error select, .form-group.error textarea {
  border: 1px solid #FF6C6C;
}

.form-group.error .help-block ul {
  padding: 0px;
  color: #FF6C6C;
}

.form-group.error .help-block ul li {
  list-style: none;
}

.form-group.validate input, .form-group.validate select, .form-group.validate textarea {
  border: 1px solid #04a08b;
}

.form-group.issue .help-block ul {
  padding: 0px;
  color: #F6B749;
}

.form-group.issue .help-block ul li {
  list-style: none;
}


/*
---------------------------------------------
preloader
--------------------------------------------- 
*/
.loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 70px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999;
  margin: 0;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}

.loader-wrapper .loader {
  position: absolute;
  top: 50%;
  left: 40%;
  margin-left: 10%;
  transform: translate3d(-50%, -50%, 0);
}

.loader-wrapper .loader .dot {
  width: 12px;
  height: 12px;
  background: #2F5572;
  border-radius: 100%;
  display: inline-block;
  animation: slide 1.2s infinite;
}

.loader-wrapper .loader .dot:nth-child(1) {
  animation-delay: 0.1s;
  background: #ffffff;
}

.loader-wrapper .loader .dot:nth-child(2) {
  animation-delay: 0.2s;
  background: #ffffff;
}

.loader-wrapper .loader .dot:nth-child(3) {
  animation-delay: 0.3s;
  background: #ffffff;
}

@-moz-keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@-o-keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
::selection {
  background: #404BCB;
  color: #fff;
}

::-moz-selection {
  background: #404BCB;
  color: #fff;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-bottom {
  -ms-flex-item-align: flex-end !important;
  align-self: flex-end !important;
}

.section {
  padding-top: 100px;
  padding-bottom: 80px;
  position: relative;
}

.section.services-section {
  margin-top: -200px;
  position: relative;
  z-index: 10;
}

.section.background {
  background: #F4F8FB;
}

.section.ptop-10 {
  padding-top: 110px;
}

.page .cover {
  min-height: 350px;
  position: relative;
}

.page .cover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 10%;
  right: 0px;
  bottom: 0px;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  z-index: 2;
  opacity: 0.85;
}

@media (max-width: 720px) {
  .page .cover:before {
 /* top: 20%; */
  }

}
.page .cover .page-top {
  position: absolute;
  width: 100%;
  bottom: 50px;
  z-index: 3;
}

.page .cover .page-top h1 {
  font-weight:500;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 1.4px;
  color: #fff;
  text-align: center;
}

.page .cover .page-top .breadcrumb {
  float: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  background: none;
  padding: 0px;
  margin-top: 8px;
  margin-bottom: 0px;
  display: block;
  text-align: center;
}

.page .cover .page-top .breadcrumb li {
  font-weight:500;
  font-size: 14px;
  color: #F7F8FF;
  line-height: 40px;
  margin-left: 10px;
  display: inline-block;
}

.page .cover .page-top .breadcrumb li:after {
  font-family: FontAwesome;
  content: "\f105";
  margin-left: 10px;
  color: #F7F8FF;
}

.page .cover .page-top .breadcrumb li:first-child {
  margin-left: 0px;
}

.page .cover .page-top .breadcrumb li:last-child:after {
  content: "";
  margin-left: 0px;
}

.page .cover .page-top .breadcrumb li a {
  font-weight:500;
  font-size: 14px;
  color: #fff;
}

.page .page-bottom {
  padding-top: 100px;
  padding-bottom: 100px;
  font-weight:500;
  font-size: 16px;
  letter-spacing: 0.88px;
  line-height: 40px;
  color: #647E95;
}

.page .page-bottom p {
  margin-bottom: 28px;
}

.page .page-bottom .home-services-item {
  margin-top: 0px;
  margin-bottom: 30px;
}

.page-gallery {
  height: 400px;
  display: block;
  overflow: hidden;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 30px;
}

.page-gallery.small {
  height: 185px;
}

.mtop-70 {
  margin-top: 70px !important;
}

.mbottom-20 {
  margin-bottom: 20px !important;
}

.mbottom-30 {
  margin-bottom: 30px !important;
}

.mbottom-60 {
  margin-bottom: 60px !important;
}

.mbottom-0 {
  margin-bottom: 0px !important;
}

.pbottom-70 {
  padding-bottom: 70px !important;
}

.ptop-60 {
  padding-top: 60px !important;
}

.pbottom-30 {
  padding-bottom: 30px !important;
}

.pbottom-60 {
  padding-bottom: 60px !important;
}

blockquote {
  margin-bottom: 28px;
  padding: 30px;
  padding-top: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #F4F8FB;
  overflow: hidden;
}

blockquote .text {
  position: relative;
  text-align: center;
}

blockquote .text p {
  position: relative;
  width: 100%;
  z-index: 2;
  margin-bottom: 10px !important;
}

blockquote .text:before {
  font-family: FontAwesome;
  content: "\f10d";
  position: absolute;
  left: 0px;
  top: 0px;
  color: #3B566E;
  z-index: 1;
  font-size: 40px;
  color: #e1ecf4;
}

blockquote .text:after {
  font-family: FontAwesome;
  content: "\f10e";
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: #3B566E;
  z-index: 1;
  font-size: 40px;
  color: #e1ecf4;
}

blockquote .author {
  color: #3B566E;
  text-align: center;
}

.left-heading .section-title {
  font-weight:500;
  font-size: 28px;
  color: #3B566E;
  letter-spacing: 1.75px;
  line-height: 42px;
  margin-bottom: 20px !important;
}

.center-heading {
  text-align: center;
}

.center-heading .section-title {
  font-weight:500;
  font-size: 30px;
  color: #032644;
  letter-spacing: 1.3px;
  line-height: 40px;
  margin-bottom: 20px !important;
  margin-top: -10px !important;
}

.left-text {
  font-weight:500;
  font-size: 16px;
  color: #647E95;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 50px;
}

.left-text p {
  margin-bottom: 30px;
}

.center-text {
  text-align: center;
  font-weight:500;
  font-size: 16px;
  color: #647E95;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 50px;
}

.center-text p {
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .page .cover h1 {
    font-size: 26px !important;
    line-height: 36px !important;
  }

  .page .cover .page-top {
    text-align: center;
  }

  .page .cover .page-top .breadcrumb {
    float: none;
    text-align: center;
    display: block;
  }

  .page .cover .page-top .breadcrumb li {
    display: inline;
  }

  .page-gallery {
    height: 185px;
  }
}

/* 
---------------------------------------------
buttons
--------------------------------------------- 
*/
.btn-white {
  display: block;
  width: 225px;
  height: 45px;
  line-height: 44px;
  color: #404BCB;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.81px;
  border: 1px solid #fff;
  border-radius: 100px;
  background: #fff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-align: center;
  cursor: pointer;
}

.btn-white:hover {
  background: none;
  color: #fff;
}

.btn-white-line {
  display: block;
  width: 225px;
  height: 45px;
  line-height: 45px;
  color: #fff;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.81px;
  border: 1px solid #fff;
  border-radius: 100px;
  background: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-align: center;
  cursor: pointer;
}

.btn-white-line:hover {
  background: #fff;
  color: #404BCB;
}

.btn-primary-line {
  display: block;
  width: 225px;
  height: 45px;
  line-height: 45px;
  color: #404BCB;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.81px;
  border: 1px solid #404BCB;
  border-radius: 100px;
  background: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-align: center;
  cursor: pointer;
}

.btn-primary-line:hover {
  background: #404BCB;
  color: #fff;
  box-shadow: rgb(38, 57, 77) 0px 20px 25px -10px;
  margin-bottom: 2px;
}

/*
---------------------------------------------
offers 
--------------------------------------------- 
*/
.offers{
  width:800px !important;
  background: url("../assets/offers/Untitled_design.png") no-repeat center !important;
  background-size:cover !important;
}
.offerTitle h2{
  color: red;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.offerTitle p{
  color: rgb(255, 136, 0);
  font-weight: bold;
  font-size: 10px;
  margin-bottom: 0 !important;
}
.offerTopic{
  color: #000;
  font-weight: bold;
}
		.button{
			/* background-image: -webkit-linear-gradient(top, #f4f1ee, #fff); */
			/* background-image: linear-gradient(top, #f4f1ee, #fff); */
      background-color: #ffff;
			border-radius: 30px 0px 30px 0px;
			box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, .3), inset 0px 4px 1px 1px white, inset 0px -3px 1px 1px rgba(204,198,197,.5);
			/* float:left; */
			height:auto;
			margin: 0 30px 15px 0;
			position: relative;
			-webkit-transition: all .1s linear;
			transition: all .1s linear;
      padding: 10px;
      cursor: pointer;
		}

		.button:after{
			color:#e9e6e4;
			content: "";
			display: block;
			font-size: 30px;
			height: 30px;
			text-decoration: none;
			text-shadow: 0px -1px 1px #bdb5b4, 1px 1px 1px white;
			position: absolute;
			width: 30px;
		}
		.tick:after{
			content: "✔";
			left:23px;
			top:16px;
      padding-right: 10px;
		}
		.button:hover{
			background-image: -webkit-linear-gradient(top, #fff, #f4f1ee);
			background-image: linear-gradient(top, #fff, #f4f1ee);
			color:#0088cc;
      margin-bottom: 5px;
		}

		.heart:hover:after{
			color:#f94e66;
			text-shadow:0px 0px 6px #f94e66;
		}

		.flower:hover:after{
			color:#f99e4e;
			text-shadow:0px 0px 6px #f99e4e;
		}

		.tick:hover:after{
			color:#83d244;
			text-shadow:0px 0px 6px #83d244;
		}

		.cross:hover:after{
			color:#eb2f2f;
			text-shadow:0px 0px 6px #eb2f2f;
		}

		

		.button:active{
			background-image: -webkit-linear-gradient(top, #efedec, #f7f4f4);
			background-image: linear-gradient(top, #efedec, #f7f4f4);
			box-shadow: 0 3px 5px 0 rgba(0,0,0,.4), inset 0px -3px 1px 1px rgba(204,198,197,.5);
		}

		.button:active:after{
			color:#dbd2d2;
			text-shadow: 0px -1px 1px #bdb5b4, 0px 1px 1px white;
		}

    .offers ul{margin-left: 20px;}
    .offers span{font-size: 20px;}

    @media(max-width: 760px){
      .EnquiryForm .offers{
        width: 300px !important;
        height: 500px;
        overflow-y: scroll  ;
        padding: 20px 10px;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 0px 0px 32px 3px #b5afaf;
      }
    .offers ul{margin-left: 0px;}
    .offers span{font-size: 11px;}

    .tick:after{
			content: "✔";
			left:23px;
			top:16px;
      padding-right: 10px;
      font-size: 10px;
		}
    }
/*
---------------------------------------------
EnquiryForm 
--------------------------------------------- 
*/
.EnquiryForm{
  z-index: 99;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #ffffff8c;
  
  /* opacity: 0.8; */
}
.EnquiryForm .row{
  width: 400px;
  padding: 20px 10px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 32px 3px #b5afaf;
}
.EnquiryForm .row .actionClose{
  display: flex;
  justify-content:space-between;
}
.EnquiryForm .row .actionClose span{
cursor: pointer;
color: red;
margin-bottom: 10px;
}
.EnquiryForm .contact-form input{
  margin-bottom:10px
}
.EnquiryForm .contact-form textarea{
  height: 90px;
}
.EnquiryForm .contact-form .action-button{
  width: 100%;
}
.EnquiryForm .contact-form .btn-primary-line{
  margin: auto;
  line-height: normal;
}
.EnquiryForm .contact-form .btn-primary-line:hover{
  color: #fff;
  border: 1px solid #48c858;
  background: #48c858;
}
.EnquiryForm .contact_us_Head{
 text-decoration: underline;
}
@media(max-width: 760px){
  .EnquiryForm .row{
    width: 300px;
    padding: 20px 10px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 32px 3px #b5afaf;
  }
}

/*
---------------------------------------------
EnquiryButton message
--------------------------------------------- 
*/
.EnquiryButton{
  z-index: 99;
  position: fixed;
  top: 50%;
  left: -40px;
  transform: rotate(-90deg);
}
.EnquiryButton h5 {
  background: red;
  padding: 10px;
  color: #fff;
  border-radius: 5px 20px 5px;
  transition: 0.2s ease-in-out;
}
.EnquiryButton h5:hover{
  background: #48c858;
  cursor: pointer;
}
.mobileScreenEnquiry{
  display: none;
}

@media(max-width: 720px){
.EnquiryButton{
  z-index: 100;
  position: fixed;
  top: 2%;
  right: 60px;
  left: auto;
  transform: rotate(0deg);
}
.EnquiryButton img{
width: 30px;
}
.bigScreenEnquiry{
  display: none;
}
.mobileScreenEnquiry{
  display: block;
}
}


/*
---------------------------------------------
whatsapp message
--------------------------------------------- 
*/
.whatsapp_contact{
  z-index: 99;
  position: fixed;
  bottom: 40px;
  right: 30px;
}
.whatsapp_contact img{
  width: 50px;
  height: 50px;
  animation: zoom-in-zoom-out 2s ease-out infinite;
 }
.whatsapp_contact img:hover{
  cursor: pointer;
  animation: zoom-in-zoom-out 2s ease;
 }
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}
/* 
---------------------------------------------
navbar
--------------------------------------------- 
*/
.navbar{
  position: fixed !important;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2%;
  background-color: #fff;
  z-index: 99;
  border-bottom: 1px solid #f9f9f9;
}
.nav-menu{
  display: flex;
  align-items: center;
}
.navbar .logo{
  width: 70px;
}
.nav-branding{
  font-size: 2rem;
}
/* transition for links */
.nav-item a{
  transition: 0.2s ease-in-out;
  color: #032644;
  font-weight: 500;
}
.nav-item a:hover{
background: -webkit-linear-gradient(122deg, rgba(140,13,233,1) 26%, rgba(18,108,213,1) 57%, rgba(136,228,194,1) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
 margin-bottom: 5px;
}

.hamburger{
  display: none;
  cursor: pointer;
}
.bar{
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 26%, rgba(18,108,213,1) 57%, rgba(136,228,194,1) 100%);
}

.navbar ul{
  padding: 0;
  margin: 0;
}
.nav-item{
  padding: 0 1rem;
}
@media(max-width: 760px){
  .nav-item a{
    transition: 0.5s ease;
    color: #fff;
    font-weight: 500;
  }
  .bar{
    display: block;
  }
  .hamburger{
    display: block;
    padding-right: 10px;
  }
  .hamburger.active .bar:nth-child(2){
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1){
    transform: rotate(45deg) translateY(11.5px); 
  }  
  .hamburger.active .bar:nth-child(3){
    transform: rotate(-45deg) translateY(-11.5px); 
  }
  .nav-menu{
    position: fixed;
    right: -100%;
    top: 70px;
    height: 100vh;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background: rgb(140,13,233);
    background: linear-gradient(122deg, rgba(140,13,233,1) 26%, rgba(18,108,213,1) 57%, rgba(136,228,194,1) 100%);
    transition: 0.3s;
    opacity: 0.9;
  }
  .nav-item{
    padding: 1rem;
  }
  
  .nav-menu.active{
    right: 0;
  }
  
}

/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.header-area {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  height: 100px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #fff;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
}
.header-area .navCover{
  margin: 0px 30px;
}

.header-area .main-nav .logo {
  width: 100px;
  float: left;
  margin-top: 15px;
  margin-right: 100px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .logo img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .dark-logo {
  display: none;
}

.header-area .main-nav .nav {
  float: right;
  margin-top: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .nav li {
  margin-left: 35px;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 600;
  font-size: 18px;
  color: #3B566E;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 36px;
  line-height: 36px;
  border: transparent;
}

.header-area .main-nav .nav li a:hover {
  color: #647E95;
}

.header-area .main-nav .nav li a.btn-nav-line {
  border: 1px solid #404BCB;
  color: #404BCB;
  width: 140px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.header-area .main-nav .nav li a.btn-nav-line:hover {
  background: #404BCB;
  color: #fff;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 23px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #3B566E;
  display: block;
  position: absolute;
  width: 26px;
  height: 2px;
  left: 0;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #3B566E;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.header-area .main-nav .menu-trigger span:after {
  width: 22px;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span, .header-area .main-nav .menu-trigger.active span:before, .header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #3B566E;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #3B566E;
}

.header-area.header-sticky {
  background: #fff;
  height: 80px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
}

.header-area.header-sticky .logo {
  margin-top: 22px;
}

.header-area.header-sticky .light-logo {
  display: none;
}

.header-area.header-sticky .nav {
  margin-top: 20px !important;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    margin-left: 30px !important;
  }
}

@media (max-width: 991px) {
  .header-area {
    background: #fff;
    height: 80px;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  }

  .header-area .container {
    padding: 0px;
  }

  .header-area .logo {
    margin-top: 22px !important;
    margin-left: 30px;
  }

  .header-area .light-logo {
    display: none !important;
  }

  .header-area .dark-logo {
    display: block !important;
  }

  .header-area .menu-trigger {
    display: block !important;
  }

  .header-area .main-nav {
    overflow: hidden;
  }

  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    margin-top: 80px !important;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
  }

  .header-area .main-nav .nav li {
    width: 100%;
    margin-left: 15px !important;
    background: #fff;
    margin: 0px !important;
    border-bottom: 1px solid #f5f5f5;
  }

  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    padding-left: 30px !important;
    border: none !important;
    background: #fff !important;
    color: #3B566E !important;
  }

  .header-area .main-nav .nav li a:hover {
    background: #f5f5f5 !important;
  }

  .header-area .main-nav .nav li a.btn-nav-line {
    border: none !important;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: left;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
  }
}

@media (min-width: 992px) {
  .header-area .main-nav .nav {
    display: flex !important;
  }
}

/* 
---------------------------------------------
welcome area
--------------------------------------------- 
*/

.welcome-area {
  min-height: 637px;
  height: auto;
  position: relative;
  background:url("../assets//slider/technology.jpg");
  background-position: top;
 background-repeat: no-repeat;
 background-size: cover;
}

.welcome-area .welcome-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.welcome-area .welcome-bg:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.85;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  z-index: 3;
}

.welcome-area .welcome-bg:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: none;
  z-index: 6;
}

.welcome-area .welcome-bg .welcome-clip {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: url("") bottom center no-repeat;
  bottom: 0px;
}

.welcome-area .welcome-content {
  position: absolute;
  width: 100%;
  top: 25%;
  left: 0;
  z-index: 11;
  transform: perspective(1px) translateY(0%);
}

.welcome-area .welcome-content h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 1.4px;
  color: #fff;
  margin-bottom: 30px !important;
}

.welcome-area .welcome-content p {
  font-weight:500;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.88px;
  left: 0.53px;
  color: #fff;
  margin-bottom: 30px;
}

.welcome-area .welcome-content .apps {
  padding-top: 30px;
  height: 300px;
  background:url("../assets/slider/apps.png");
  background-position: top;
 background-repeat: no-repeat;
 background-size: cover;
}
.animatedText{
  font-size: 100px;
   animation: TextColor 4s linear infinite;
  -webkit-animation: TextColor 4s ease-in-out infinite;
}

@keyframes TextColor{
  0%{
    color:#83f10c;
  }
  
  20%{
    color:#08e3f3;
  }
  
  40%{
    color:#f1b211;
  }
  
  60%{
    color:#ffffff;
  }
  
  80%{
    color:#f1bb0b;
  }
  
  100%{
    color:#08f070;
  }
}

@media (max-width: 991px) {
  .welcome-area .welcome-content {
    position: relative;
    -webkit-transform: translateY(0%) !important;
    transform: translateY(0%) !important;
    padding-top: 100px;
  }

  .welcome-area .welcome-content h1 {
    font-size: 26px !important;
    line-height: 36px !important;
  }

  .welcome-area .welcome-content .apps {
    margin-top: 30px;
  }

  .welcome-area .welcome-content .apps .app-item {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .welcome-area .welcome-content .apps{
    background-size: contain;
  }
}

/* 
---------------------------------------------
home
--------------------------------------------- 
*/
.home-services-item {
  display: block;
  background-image: linear-gradient(127deg, #fff 0%, #fff 91%);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  font-weight:500;
  font-size: 14px;
  color: #647E95;
  line-height: 28px;
  letter-spacing: 0.88px;
  padding: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  height: 250px;
}
.home-services-items {
  display: block;
  background-image: linear-gradient(127deg, #fff 0%, #fff 91%);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  font-weight:500;
  font-size: 14px;
  color: #647E95;
  line-height: 28px;
  letter-spacing: 0.88px;
  padding: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  height: 370px;
  text-align: center;
}
.home-services-items .shadow {
  margin-top: 5px;
  width: 25px;
  height: 5px;
  background-color: #bbb7b7;
  margin: auto;
  border-radius: 50%;
}
.home-services-items:hover .shadow {
  width: 30px;
  height: 5px;
  background-color: #bbb7b7;
  margin: auto;
  border-radius: 50%;
}
.home-services-items h5 {
margin-top: 30px;
}

.home-services-items img{
margin-bottom: 10px;
  transition: transform .5s ease-in-out; 
}

.home-services-items:hover img{
  transform:rotate(10deg);
}


.home-services-item.box {
  border-radius: 4px !important;
}

.home-services-item.box:before {
  border-radius: 4px !important;
}

.home-services-item:before {
  content: "";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 5px;
  background: red;
  bottom: 0px;
  left: 0px;
  z-index: 1;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  opacity: 1;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.home-services-item:hover, .home-services-item.active {
  color: #fff;
}

.home-services-item:hover:before, .home-services-item.active:before {
  height: 100%;
}

.home-services-item:hover .services-title, .home-services-item.active .services-title {
  color: #fff;
}

.home-services-item:hover i, .home-services-item.active i {
  background-image: linear-gradient(127deg, #fff 0%, #fff 91%);
}

.home-services-item .services-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #3B566E;
  margin-bottom: 23px !important;
  letter-spacing: 0.7px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 2;
}

.home-services-item i {
  font-size: 26px;
  margin-bottom: 23px;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 2;
}

.home-services-item p {
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin-bottom: 0px !important;
}

.home-services-item .btn-white-line {
  position: relative;
  z-index: 2;
  width: 100%;
}
.features{
  padding: 0;
}
.features li {
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.features li:before {
  content: "";
  position: absolute;
  width: calc(100% - 18px);
  width: -webkit-calc(100% - 18px);
  right: 0px;
  height: 100%;
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 1;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
}

.features li:hover .count:before, .features li.active .count:before {
  height: 100%;
}

.features li:hover .count span, .features li.active .count span {
  color: #fff;
}

.features li .count {
  float: left;
  width: 37px;
  height: 37px;
  position: relative;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0 2px 13px 0 rgba(203, 211, 221, 0.36);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  z-index: 3;
  margin-top: 16px;
}

.features li .count:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0px;
  bottom: 0px;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  z-index: 1;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.features li .count span {
  display: block;
  width: 37px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  margin: auto;
  font-weight: 500;
  font-size: 16px;
  color: #404BCB;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.features li .text {
  float: left;
  width: calc(100% - 68px);
  width: -webkit-calc(100% - 68px);
  padding-left: 20px;
}

.features li .text .title {
  font-weight:500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
  color: #032644;
  margin-top: 20px;
  margin-bottom: 24px !important;
  position: relative;
  z-index: 2;
}

.features li .text p {
  font-weight:500;
  font-size: 14px;
  color: #647E95;
  letter-spacing: 0.88px;
  line-height: 28px;
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
}

.team-item {
  background-image: linear-gradient(127deg, #fff 0%, #fff 91%);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  padding: 25px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.team-item:before {
  content: "";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 5px;
  background: red;
  bottom: 0px;
  left: 0px;
  z-index: 1;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.team-item:hover:before {
  height: 100%;
}

.team-item:hover .header .info strong {
  color: #fff;
}

.team-item:hover .header .info span {
  color: #F7F8FF;
}

.team-item:hover .body {
  color: #F7F8FF;
}

.team-item .header {
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.team-item .header .img {
  float: left;
  width: 76px;
  margin-right: 20px;
  overflow: hidden;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.team-item .header .info strong {
  display: block;
  font-weight:500;
  font-size: 16px;
  line-height: 26px;
  color: #032644;
  letter-spacing: 1px;
  margin-top: 0px;
  margin-bottom: 3px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.team-item .header .info span {
  display: block;
  font-weight:500;
  font-size: 13px;
  color: #647E95;
  letter-spacing: 0.52px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.team-item .body {
  font-weight:500;
  font-size: 14px;
  line-height: 28px;
  color: #647E95;
  letter-spacing: 0.88px;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.social{
  padding: 0;
}
#media:hover{
  filter: drop-shadow(4px 5px 1px rgb(163 164 165));
  transition: 0.2s ease-in-out;
  margin-left: 1px;
}
.team-item .social {
  padding-left: 0;
  position: absolute;
  right: 0px;
  top: 83px;
  overflow: hidden;
  width: 100px;
  height: 28px;
  background: #fff;
  text-align: center;
  border-radius: 3px 0px 0px 3px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 2;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
}

.team-item .social li {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  margin-left: 5px;
  margin-right: 5px;
}

.team-item .social li i {
  color: #404BCB;
}

.parallax {
  min-height: 280px;
  position: relative;
}

.parallax:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.78;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  z-index: 2;
}

.parallax .parallax-content {
  position: relative;
  z-index: 3;
}

.parallax .parallax-content .count-item {
  height: 280px;
  position: relative;
  overflow: hidden;
}

.parallax .parallax-content .count-item:hover strong {
  margin-top: 60px;
}

.parallax .parallax-content .count-item strong {
  display: block;
  text-align: center;
  font-weight:500;
  font-size: 40px;
  letter-spacing: 1.72px;
  margin-bottom: 10px;
  color: #fff;
  margin-top: 85px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.parallax .parallax-content .count-item .Apply{
  display: block;
  text-align: center;
  font-weight:400;
  font-size: 25px;
  letter-spacing: 1.72px;
  margin-bottom: 10px;
  color: #fff;
  margin-top: 85px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.parallax .parallax-content .count-item span {
  display: block;
  text-align: center;
  color: #fff;
  font-weight:500;
  font-size: 20px;
  letter-spacing: 0.86px;
}

.parallax .parallax-content .info {
  text-align: center;
  font-weight:500;
  font-size: 22px;
  color: #fff;
}

.parallax .parallax-content .info p {
  letter-spacing: 1px;
  margin-top: 70px;
  margin-bottom: 60px;
}

.parallax .parallax-content .info p span {
  text-decoration: underline;
}

.parallax .parallax-content .info .btn-white-line {
  margin: auto;
}

@media (max-width: 991px) {
  .home-services-item.mtop-70 {
    margin-top: 30px !important;
  }

  .services-section {
    margin-top: 0px !important;
  }

  .team-item {
    cursor: pointer;
  }

  .parallax .parallax-content.mobile-parallax-fix {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .parallax .parallax-content .count-item {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .parallax .parallax-content .count-item:hover strong {
    margin-top: 0px;
  }

  .parallax .parallax-content .count-item:before {
    display: none;
  }

  .parallax .parallax-content .count-item:after {
    width: 40px;
    height: 1px;
  }

  .parallax .parallax-content .count-item strong {
    margin-top: 0px;
  }

  .parallax .parallax-content .info .btn-white-line {
    margin-bottom: 80px;
  }
}

/* 
---------------------------------------------
blog
--------------------------------------------- 
*/
.blog-post-thumb {
  margin-bottom: 30px;
}

.blog-post-thumb.big .img {
  height: 400px;
}

.blog-post-thumb .img {
  overflow: hidden;
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  position: relative;
  height: 200px;
}

.blog-post-thumb .img .date {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 60px;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
}

.blog-post-thumb .img .date strong {
  display: block;
  color: #fff;
  height: 20px;
  line-height: 20px;
}

.blog-post-thumb .img .date span {
  display: block;
  color: #fff;
  height: 20px;
  line-height: 20px;
}

.blog-post-thumb .blog-text {
  height: 400px;
  padding: 20px 30px;
  background: #fff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  overflow: hidden;
}

.blog-post-thumb .blog-text h3 {
  margin-bottom: 24px !important;
}

.blog-post-thumb .blog-text h3 a {
  font-weight:500;
  font-size: 16px;
  color: #032644;
  letter-spacing: 1px;
  line-height: 26px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.blog-post-thumb .blog-text h3 a:hover {
  color: #404BCB;
}

.blog-post-thumb .blog-text .post-meta {
  overflow: hidden;
  margin-bottom: 5px;
}

.blog-post-thumb .blog-text .post-meta li {
  float: left;
  margin-right: 30px;
  height: 30px;
  line-height: 40px;
  color: #404BCB;
  font-size: 14px;
}

.blog-post-thumb .blog-text .post-meta li a {
  color: #404BCB;
}

.blog-post-thumb .blog-text .post-meta li span {
  display: inline-block;
  margin-right: 5px;
}

.blog-post-thumb .blog-text .text {
  font-weight:500;
  font-size: 14px;
  color: #647E95;
  letter-spacing: 0.88px;
  line-height: 40px;
  margin-bottom: 15px;
}

.blog-post-thumb .blog-text .text .post-detail {
  font-weight:500;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
}
.post-detail p >  b {
 color: #032644;
}

.blog-post-thumb .blog-text .post-footer {
  overflow: hidden;
  border-bottom: 1px solid #ECF7FF;
  border-top: 1px solid #ECF7FF;
  padding-top: 10px;
  padding-bottom: 10px;
}

.blog-post-thumb .blog-text .post-footer span {
  float: left;
  height: 30px;
  line-height: 40px;
  font-weight: 300;
  font-size: 14px;
  color: #647E95;
}

.blog-post-thumb .blog-text .post-footer ul.share {
  float: right;
}

.blog-post-thumb .blog-text .post-footer ul.share li {
  float: left;
  margin-left: 15px;
}

.blog-post-thumb .blog-text .post-footer ul.share li a {
  display: block;
  height: 30px;
  line-height: 40px;
  color: #404BCB;
}

.blog-post-thumb .blog-text .btn-primary-line {
  margin: auto;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
}

.blog-list .blog-post-thumb {
  margin-bottom: 30px;
  text-align: left;
}

.blog-list .blog-post-thumb .btn-primary-line {
  float: left;
}

.section-comments {
  margin-bottom: 60px;
  margin-top: 30px;
}

.section-comments h5 {
  margin-bottom: 40px !important;
}

.section-comments ul {
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}

.section-comments ul li {
  overflow: hidden;
}

.section-comments ul li .avatar {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  overflow: hidden;
  float: left;
  margin-right: 20px;
}

.section-comments ul li .comment-content {
  font-weight:500;
  font-size: 14px;
  color: #647E95;
  float: left;
  width: calc(100% - 90px);
  width: -webkit-calc(100% - 90px);
}

.section-comments ul li .comment-content .comment-by {
  margin-bottom: 10px;
  position: relative;
}

.section-comments ul li .comment-content .comment-by strong {
  display: block;
  color: #3B566E;
}

.section-comments ul li .comment-content .comment-by span {
  font-size: 12px;
}

.section-comments ul li .comment-content .comment-by .btn-reply {
  position: absolute;
  right: 0px;
  top: 15px;
  background: #F4F8FB;
  color: #647E95;
  height: 25px;
  line-height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.section-comments ul li .comment-content .comment-by .btn-reply i {
  font-size: 12px;
}

.section-comments ul li .comment-content .comment-by .btn-reply:hover {
  background: #404BCB;
  color: #fff;
}

.section-comments ul li ul {
  padding-left: 90px;
}

.post-comment {
  background: #F4F8FB;
  padding: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 30px;
}

.post-comment .comment-form .form-item {
  margin-bottom: 20px;
}

.post-comment .comment-form .form-item label {
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0px;
}

.post-comment .comment-form .form-item input, .post-comment .comment-form .form-item textarea {
  border: 1px solid #eee;
  width: 100%;
  height: 50px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.post-comment .comment-form .form-item input:focus, .post-comment .comment-form .form-item textarea:focus {
  border: 1px solid #404BCB;
}

.post-comment .comment-form .form-item textarea {
  height: 150px;
  resize: none;
  padding: 20px;
}

.pagination {
  margin-bottom: 30px;
}

.pagination .page-item a {
  font-size: 14px;
  color: #404BCB;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin-left: 3px;
  margin-right: 3px;
  background: #FFFFFF;
  box-shadow: 0 2px 13px 0 rgba(203, 211, 221, 0.36);
  border: none;
  position: relative;
  overflow: hidden;
  border-radius: 3px !important;
  width: 37px;
  height: 37px;
  text-align: center;
}

.pagination .page-item a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0px;
  bottom: 0px;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 1;
}

.pagination .page-item a span {
  position: relative;
  z-index: 2;
}

.pagination .page-item a:hover:before {
  height: 100%;
}

.pagination .page-item a:hover span {
  color: #fff;
}



@media (max-width: 991px) {
  .blog-post-thumb.big .img {
    height: 350px;
  }

  .section-comments ul li ul {
    padding-left: 50px;
  }
}
@media (max-width: 767px) {
  .blog-post-thumb .blog-text{
    height: auto;
  }
}

@media (max-width: 420px) {
  .blog-post-thumb.big .img {
    height: 200px;
  }
}

.tp-error__area{
  margin-top: 10%;
}

/* 
---------------------------------------------
our work
--------------------------------------------- 
*/
.page-app {
  position: relative;
  float: left;
  width: 100%;
  display: block;
  margin-bottom: 70px;
}

.page-app:hover .app-content:before {
  height: 100%;
}

.page-app:hover .app-content .title {
  color: #ffffff;
}

.page-app:hover .app-content .text {
  color: #F7F8FF;
}

.page-app:hover .app-content .stars li {
  color: #F7F8FF;
}

.page-app:before {
  content: "";
  position: absolute;
  right: 0px;
  width: 100%;
  z-index: 1;
  height: 100%;
  background: #fff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.page-app .icon {
  float: left;
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  top: -50px;
  z-index: 3;
  margin-top: 20px;
}

.page-app .app-content {
  position: relative;
  z-index: 2;
  float: right;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-top: 70px;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.page-app .app-content:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0px;
  bottom: 0px;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 1;
}

.page-app .app-content .title {
  font-weight:500;
  font-size: 16px;
  color: #3B566E;
  letter-spacing: 0.7px;
  margin-bottom: 2px !important;
  text-align: center;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.page-app .app-content .stars {
  overflow: hidden;
  margin-bottom: 15px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.page-app .app-content .stars li {
  display: inline;
  margin-right: 2px;
  font-size: 12px;
  color: #AEBCD6;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.page-app .app-content .stars li i.active {
  color: #F5A623;
}

.page-app .app-content .text {
  font-weight:500;
  font-size: 14px;
  color: #647E95;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-align: center;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.app-single .header {
  padding: 40px;
  padding-bottom: 20px;
  background: #fff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.app-single .header:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0px;
  bottom: 0px;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
}

.app-single .header .title h2 {
  display: block;
  font-weight:500;
  font-size: 24px;
  letter-spacing: 1px;
  margin-bottom: 5px !important;
}

.app-single .header .title .stars {
  overflow: hidden;
  margin-bottom: 10px;
}

.app-single .header .title .stars li {
  float: left;
  margin-right: 5px;
  font-size: 12px;
  color: #AEBCD6;
}

.app-single .header .title .stars li i.active {
  color: #F5A623;
}

.app-single .header .download-buttons {
  float: right;
  text-align: right;
  margin-top: 5px;
}

.app-single .header .download-buttons a {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
}

.app-single .item {
  padding: 40px;
  padding-bottom: 20px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 30px;
  position: relative;
}

.app-single .item:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0px;
  bottom: 0px;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
}

.app-single .item .icon {
  float: left;
  width: 30px;
}

.app-single .item .icon i {
  font-size: 26px;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.app-single .item .content {
  float: left;
  width: calc(100% - 30px);
  width: -webkit-calc(100% - 30px);
  padding-left: 30px;
}

.app-single .item .content .title {
  margin-top: 3px;
  margin-bottom: 26px !important;
}

.app-single .item .content .text {
  font-weight:500;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 28px;
  color: #647E95;
}

.app-single .item .content .text p {
  margin-bottom: 16px;
}

.app-single .item .content .text .version {
  margin-bottom: 30px;
}

.app-single .item .content .text .version h6 {
  margin-bottom: 10px !important;
}

.app-single .item .content .text .version ul li {
  font-size: 14px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.app-single .item .content .text .version ul li:hover {
  padding-left: 5px;
}

.app-single .item .content .text .version ul li:before {
  font-family: FontAwesome;
  content: "\f105";
  color: #404BCB;
  margin-right: 10px;
}

.app-single .item .content .gallery .gallery-item {
  display: block;
  overflow: hidden;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .app-single .header .icon {
    text-align: center;
    margin-bottom: 20px;
  }

  .app-single .header .title {
    text-align: center;
  }

  .app-single .header .title h2 {
    margin-bottom: 10px;
  }

  .app-single .header .title .stars {
    text-align: center;
    margin-bottom: 20px;
  }

  .app-single .header .title .stars li {
    float: none;
    display: inline;
  }

  .app-single .header .download-buttons {
    float: none;
    text-align: right;
  }

  .app-single .header .download-buttons a {
    margin-left: 0px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 420px) {
  .app-single .header .download-buttons {
    text-align: center;
  }
}

/* 
---------------------------------------------
aside
--------------------------------------------- 
*/
.default-aside{
  position: sticky;
  top: 18%;
}
aside.default-aside .sidebar .box {
  margin-bottom: 30px;
}

aside.default-aside .sidebar ul {
  border: 1px solid #ECF7FF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 30px;
}

aside.default-aside .sidebar ul li.active a {
  background: #F4F8FB;
  color: #647E95;
}

aside.default-aside .sidebar ul li.active a:after {
  color: #647E95;
}

aside.default-aside .sidebar ul li.active a:hover {
  padding-left: 60px;
}

aside.default-aside .sidebar ul li:last-child a {
  border: none;
}

aside.default-aside .sidebar ul li a {
  display: block;
  border-bottom: 1px dashed #ECF7FF;
  height: 60px;
  line-height: 60px;
  padding-left: 60px;
  position: relative;
  font-weight:500;
  font-size: 14px;
  color: #3B566E;
  letter-spacing: 0.88px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
}

aside.default-aside .sidebar ul li a:hover {
  padding-left: 70px;
}

aside.default-aside .sidebar ul li a:after {
  font-family: FontAwesome;
  content: "\f105";
  position: absolute;
  left: 30px;
  color: #3B566E;
}

aside.default-aside .sidebar ul li a span {
  float: right;
  margin-right: 30px;
  display: inline-block;
  background: #F4F8FB;
  height: 20px;
  line-height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 10px;
  color: #647E95;
  margin-top: 18px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

aside.default-aside .sidebar .search-widget {
  display: block;
  margin-bottom: 30px;
  height: 50px;
  position: relative;
}

aside.default-aside .sidebar .search-widget input {
  border: 1px solid #ECF7FF;
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
  padding-left: 20px;
  padding-right: 50px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

aside.default-aside .sidebar .search-widget input:focus {
  border: 1px solid #404BCB;
  padding-left: 30px;
}

aside.default-aside .sidebar .search-widget button {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
  height: 30px;
  width: 30px;
  border: none;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  text-align: center;
  padding: 0px;
}

aside.default-aside .sidebar .search-widget button i {
  color: #fff;
}

aside.default-aside .sidebar .widget-tags {
  border: 1px solid #F4F8FB;
  padding: 30px;
  padding-bottom: 20px;
  overflow: hidden;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

aside.default-aside .sidebar .widget-tags a {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  background: #F4F8FB;
  padding-left: 10px;
  padding-right: 10px;
  font-weight:500;
  font-size: 14px;
  color: #647E95;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

aside.default-aside .sidebar .widget-tags a:hover {
  background: #404BCB;
  color: #fff;
}

@media (max-width: 991px) {
  .default-aside {
    margin-bottom: 30px;
  }
}

/* 
---------------------------------------------
contact
--------------------------------------------- 
*/
.map-wrapper {
  position: relative;
  width: 100%;
  height: 365px;
  margin-bottom: 90px;
}

.map-wrapper .map-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
}

.map-wrapper .contact-info {
  height: 365px;
     background: rgb(140,13,233);
background: linear-gradient(122deg, rgba(140,13,233,1) 10%, rgba(18,108,213,1) 36%, rgba(136,228,194,1) 100%);
  padding: 40px;
  padding-top: 55px;
  color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
}

.map-wrapper .contact-info .item {
  overflow: hidden;
  margin-bottom: 30px;
}

.map-wrapper .contact-info .item i {
  float: left;
  font-size: 16px;
  width: 20px;
  margin-right: 10px;
  margin-top: 5px;
}

.map-wrapper .contact-info .item span {
  float: left;
  width: calc(100% - 30px);
  width: -webkit-calc(100% - 30px);
  font-size: 14px;
}

.map-wrapper .contact-info .item span a {
  color: #fff;
}

.map-wrapper .contact-info .social {
  overflow: hidden;
  margin-top: 5px;
  text-align: center;
}

.map-wrapper .contact-info .social li {
  display: inline;
  font-size: 22px;
  margin-right: 7px;
  margin-left: 7px;
}

.map-wrapper .contact-info .social li a {
  color: #fff;
}

.contact-text {
  font-weight:500;
  font-size: 14px;
  color: #647E95;
  letter-spacing: 0.6px;
  line-height: 26px;
}

.contact-text p {
  margin-bottom: 28px;
}

.contact-form input, .contact-form textarea {
  border: 1px solid #eee;
  width: 100%;
  height: 50px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 30px;
}

.contact-form input:focus, .contact-form textarea:focus {
  border: 1px solid #404BCB;
  padding-left: 30px;
}

.contact-form textarea {
  height: 150px;
  resize: none;
  padding: 20px;
}

@media (max-width: 991px) {
  .map-wrapper {
    height: auto;
  }

  .map-wrapper .map-canvas {
    position: relative;
    height: 365px;
    margin-bottom: 30px;
  }

  .map-wrapper .contact-info {
    border-radius: 5px !important;
  }
}

/* 
---------------------------------------------
footer
--------------------------------------------- 
*/
footer {
  background: #F4F8FB;
  padding-top: 70px;
  margin-top: 40px;
}

footer img.logo {
  margin-bottom: 30px;
}

footer .text {
  font-weight:500;
  font-size: 14px;
  color: #647E95;
  line-height: 26px;
  letter-spacing: 0.88px;
}

footer h5 {
  font-weight: 700;
  font-size: 16px;
  color: #032644;
  letter-spacing: 0.69px;
  line-height: 40px;
  margin-bottom: 30px !important;
}

footer .footer-nav li a {
  display: block;
  overflow: hidden;
}

footer .footer-nav li a:hover span {
  padding-left: 5px;
}

footer .footer-nav li a i {
  float: left;
  height: 32px;
  line-height: 32px;
  margin-right: 12px;
  font-size: 14px;
  color: #3B566E;
}

footer .footer-nav li a span {
  float: left;
  height: 32px;
  line-height: 32px;
  font-weight:500;
  font-size: 14px;
  color: #647E95;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

footer .address p {
  display: block;
  overflow: hidden;
  font-weight:500;
  font-size: 14px;
  color: #647E95;
  line-height: 26px;
  letter-spacing: 0.88px;
  margin-bottom: 5px;
}

footer .address p a {
  color: #404BCB;
}

footer .address .social {
  overflow: hidden;
  margin-top: 5px;
}

footer .address .social li {
  float: left;
  font-size: 16px;
  margin-right: 10px;
}

footer .address .social li a {
  color: #647E95;
}

footer .copyright {
  text-align: center;
  border-top: 1px solid #DAE6FD;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight:500;
  font-size: 14px;
  color: #032644;
  letter-spacing: 0.88px;
}

@media (max-width: 991px) {
  footer .text {
    margin-bottom: 30px;
  }

  footer h5 {
    margin-bottom: 15px;
  }

  footer .footer-nav {
    margin-bottom: 30px;
  }
}